<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      :hide-required-asterisk="true"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Prompt')" name="prompt">
          <el-scrollbar :native="false">
            <div
              style="max-height: 59vh; padding-right: 5px;padding-bottom: 10px"
            >
              <el-form-item :label="__('Prompt')">
                <input-variable-popper
                  :value="promptText"
                  is-content-editable
                  @input="updatePromptText($event)"
                  :is-text-area="false"
                  include-prompts
                  include-audio-variables
                  :include-secure-variables="false"
                  :ats="getOptionsInitiators"
                  popper-class="prompt-node-popper"
                  class="promptEditor"
                />
                <audio-player
                  class="audio-player"
                  :disabled="!promptText"
                  @get-preview="
                    generateAudio(
                      'promptText',
                      'generatingAudioForPrompt',
                      'promptAudioFile',
                      'promptAtAudioFileCreation'
                    )
                  "
                  :show-reload="promptContentChanged"
                  :generating-audio="generatingAudio"
                  :file="promptAudioFile"
                />
              </el-form-item>
              <el-form-item
                :label="__('Assign Recording File to Audio Variable')"
              >
                <create-or-select
                  :items="audioVariables"
                  label="variable_name"
                  value="variable_id"
                  :placeholder="__('Select or create a new variable')"
                  :new-item-message="__('new variable')"
                  :current_select="currentSelectionRecordFileToAudioVariable"
                  @change="handleChangeRecordFileToAudioVariable"
                />
                <el-col :span="4">
                  <span class="row-message" v-if="newVariableForAudioVar">{{
                    __("new variable")
                  }}</span>
                </el-col>
              </el-form-item>

              <el-form-item
                :label="__('Assign Recording File Public URL to Variable')"
              >
                <variable-dropdown
                  :placeholder="__('Select or create a new variable')"
                  :new-item-message="__('new variable')"
                  :studioVariables="singleValuedVariables"
                  :currentSelect="
                    currentSelection(currentSelectionAudioUrlToVariable)
                  "
                  :variableRule="recordAudioPublicUrlRuleValue"
                  @updateVariables="handleVariableUpdate"
                  :nodeData="nodeToBind.record_node"
                />
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Settings')" name="record_settings">
          <el-scrollbar :native="false">
            <el-form-item :label="__('Send To')">
              <input-variable-popper
                :value="nodeToBind.record_node.data.email_list"
                is-text-area
                :include-secure-variables="false"
                :ats="['{{']"
                popper-class="prompt-node-popper"
                @input="updateFormParameters($event)"
                :placeholder="
                  __('Add one or multiple address with comma separated')
                "
              />
            </el-form-item>
            <el-form-item
              :label="__('Custom Recording File Name')"
              prop="record_node.data.property_rules.data.recording_file_name"
            >
              <expression-input
                v-model="recordingFileName"
                :complex-variables="complexVariables"
                :placeholder="recordingNameInputPlaceholder"
                @edit-input="displayFileNameExpressionBuilderModal = true"
              ></expression-input>
            </el-form-item>
            <el-form-item :label="__('Record Duration (In Seconds)')">
              <el-select
                v-model="nodeToBind.record_node.data.record_duration"
                style="width: 100%;"
                default-first-option
                filterable
                :placeholder="__('Set a recording duration limit')"
              >
                <el-option
                  v-for="(item, index) in generateRowLimit"
                  :label="item"
                  :value="item"
                  :key="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="record_node.data.record_beep">
              <el-checkbox v-model="nodeToBind.record_node.data.record_beep">{{
                __("Play beep sound before recording starts")
              }}</el-checkbox>
            </el-form-item>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <expression-builder-dialog
      v-if="displayFileNameExpressionBuilderModal"
      v-model="recordingFileName"
      :show-expression-builder="displayFileNameExpressionBuilderModal"
      @input="handleCancelFileNameExpressionBuilderModal"
      @cancel="handleCancelFileNameExpressionBuilderModal"
      @close="handleCancelFileNameExpressionBuilderModal"
    />
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import { NODE_TYPES } from "@/constants/nodes";
import { mapState } from "vuex";
import _ from "lodash";
import InputVariablePopper from "../components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";
import CreateOrSelect from "@/views/build/callflow/components/node-type-forms/components/CreateOrSelect";
import { filterRowsIfSomeKeyValueIsAbsent } from "@/utils/collection";
import { alphaNumericRegex } from "@/utils/regex";
import ExpressionInput from "@/views/build/callflow/components/expression-builder/ExpressionInput";
import ExpressionBuilderDialog from "@/views/build/callflow/components/expression-builder/ExpressionBuilderDialog";
import { getComplexVariables } from "@/api/variables";
import { sanitizeInput } from "@/utils/promptText";
import VariableDropdown from "@/views/build/callflow/components/node-type-forms/components/VariableDropdown.vue";
import NodeUsesVariableDropdown from "@/views/build/callflow/components/node-type-forms/NodeUsesVariableDropdown.vue";

const initRecordNode = {
  node_type: NODE_TYPES.RECORD.NODE_TYPE,
  record_node: {
    data: {
      prompt_text: "",
      email_list: "",
      record_duration: "max-limit",
      record_beep: true,
      variable_rules: {
        data: []
      },
      interaction_variable_rules: {
        data: []
      },
      property_rules: {
        data: {
          recording_file_name: ""
        }
      },
      transfer_timeout: null,
      no_answer_busy_canvas_id: null,
      music_on_hold_file_id: null
    }
  }
};

const variableRuleInitialize = {
  rule_value: "",
  variable_name: "",
  variable_id: -1,
  default_value: "",
  array_variable: false
};

export default {
  components: {
    VariableDropdown,
    InputVariablePopper,
    AudioPlayer,
    CreateOrSelect,
    ExpressionBuilderDialog,
    ExpressionInput
  },
  mixins: [BaseNode, GenerateAudio, NodeUsesVariableDropdown],
  data() {
    return {
      rules: {},
      promptAudioFile: "",
      generatingAudio: false,
      promptAtAudioFileCreation: "",
      recordAudioPublicUrlRuleValue: "rec_audio_public_url_var",
      nodeName: "record_node",
      activeTab: "prompt",
      variableRuleInitialize: _.cloneDeep(variableRuleInitialize),
      variableRulesBkup: [],
      newVariableForAudioVar: 0,
      allowedRegex: alphaNumericRegex,
      displayFileNameExpressionBuilderModal: false
    };
  },
  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts
    }),
    promptText() {
      return sanitizeInput(this.nodeToBind.record_node.data.prompt_text);
    },
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    },
    generateRowLimit() {
      let row_limit = Array.from(Array(300), (_, i) => i + 1);
      return ["max-limit"].concat(row_limit);
    },
    currentSelectionRecordFileToAudioVariable() {
      let variableRules = _.find(
        this.nodeToBind.record_node.data.variable_rules.data,
        rule => rule.rule_value === "rec_audio_var"
      );

      const { variable_id, variable_name } = _.isEmpty(variableRules)
        ? this.variableRuleInitialize
        : variableRules;
      return variable_id === -1 ? variable_name : variable_id;
    },
    currentSelectionAudioUrlToVariable() {
      return (
        _.find(
          this.nodeToBind.record_node.data.variable_rules.data,
          rule => rule.rule_value === this.recordAudioPublicUrlRuleValue
        ) ||
        _.find(
          this.nodeToBind.record_node.data.interaction_variable_rules.data,
          rule => rule.rule_value === this.recordAudioPublicUrlRuleValue
        ) ||
        this.variableRuleInitialize
      );
    },

    recordingFileName: {
      get() {
        return (
          this.nodeToBind.record_node.data.property_rules.data
            .recording_file_name || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.record_node.data.property_rules.data.recording_file_name = expression;
      }
    },

    /**
     * Content for the recording name input placeholder
     * @returns {String} Translated content for the recording name input placeholder
     */
    recordingNameInputPlaceholder() {
      // eslint-disable-next-line
      return __("Specify a name for the recorded audio using the expression builder");
    }
  },
  async created() {
    if (_.isEmpty(this.complexVariables) && !_.isEmpty(this.clickedNode)) {
      await getComplexVariables(this.clickedNode.task_id).then(({ data }) => {
        this.complexVariables = data.data;
      });
    }

    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.record_node)) {
      this.initializeRecordNodeData();
    }
  },
  methods: {
    initializeRecordNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initRecordNode)
      );
    },
    updatePromptText(value) {
      this.$set(
        this.nodeToBind.record_node.data,
        "prompt_text",
        sanitizeInput(value)
      );
    },
    handleChangeRecordFileToAudioVariable(option) {
      this.newVariableCreated = option.value === -1;
      this.newVariableForAudioVar = _.isEmpty(option.msg) ? 0 : 1;
      const recordFileAudioVarObj = {
        variable_id: option.value,
        variable_name: option.label,
        variable_type: "audio",
        msg: option.msg,
        rule_value: "rec_audio_var"
      };
      if (_.isEmpty(this.nodeToBind.record_node.data.variable_rules)) {
        this.$set(this.nodeToBind.record_node.data, "variable_rules", {});
        this.$set(this.nodeToBind.record_node.data.variable_rules, "data", []);
      } else {
        _.remove(
          this.nodeToBind.record_node.data.variable_rules.data,
          obj => obj.rule_value === "rec_audio_var"
        );
      }
      this.nodeToBind.record_node.data.variable_rules.data.push(
        recordFileAudioVarObj
      );
    },

    handleCancelFileNameExpressionBuilderModal() {
      this.displayFileNameExpressionBuilderModal = false;
    },
    updateFormParameters(value) {
      this.$set(this.nodeToBind.record_node.data, "email_list", value);
    },
    cleanUpVariableRulesBeforeSubmit() {
      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        this.nodeToBind.record_node.data.variable_rules.data,
        "variable_name"
      );
      this.$set(this.nodeToBind.record_node.data.variable_rules, "data", []);
      this.nodeToBind.record_node.data.variable_rules.data = filteredVariableRulesData;
    },
    cleanUpNodeToPrepareForSubmit() {
      // do necessary operations on a cloned version of nodeToBind obj
      // that return the nodeToBind object
      // in exactly the same way as an node object of this
      // type is returned from the backend

      // as I do not require any cleanup to do here in this particular case,
      // I am just sending back a cloned version of nodeToBind obj
      return _.cloneDeep(this.nodeToBind);
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      this.cleanUpVariableRulesBeforeSubmit();
      this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
      this.newVariableCreated = _.find(
        this.nodeToBind.record_node.data.variable_rules.data,
        rule => rule.variable_id === -1
      );
      this.createOrEditNode();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";
.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}
.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    &:focus {
      border: 1px solid black;
    }
  }
}
</style>
